import { render, staticRenderFns } from "./DrawerRight.vue?vue&type=template&id=7235bd3e&"
import script from "./DrawerRight.vue?vue&type=script&lang=ts&"
export * from "./DrawerRight.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DrawerRightLink: require('/home/caramaps/site/dvp/www2/releases/20241010132410/components/DrawerRightLink.vue').default})
