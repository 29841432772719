import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _54ed8f9c = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _de659614 = () => interopDefault(import('../pages/caramaps_host.vue' /* webpackChunkName: "pages/caramaps_host" */))
const _4d640226 = () => interopDefault(import('../pages/activities/index.vue' /* webpackChunkName: "pages/activities/index" */))
const _4373a592 = () => interopDefault(import('../pages/caras/ambassador.vue' /* webpackChunkName: "pages/caras/ambassador" */))
const _3b50da72 = () => interopDefault(import('../pages/mediakit/index.vue' /* webpackChunkName: "pages/mediakit/index" */))
const _c2532efa = () => interopDefault(import('../pages/directory/index.vue' /* webpackChunkName: "pages/directory/index" */))
const _60152ca3 = () => interopDefault(import('../pages/application.vue' /* webpackChunkName: "pages/application" */))
const _d8459826 = () => interopDefault(import('../pages/application-news.vue' /* webpackChunkName: "pages/application-news" */))
const _1e30589a = () => interopDefault(import('../pages/awards.vue' /* webpackChunkName: "pages/awards" */))
const _5ec5d1af = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _ae4510ac = () => interopDefault(import('../pages/map/_slug.vue' /* webpackChunkName: "pages/map/_slug" */))
const _1e1061f2 = () => interopDefault(import('../pages/caras/leaderboard.vue' /* webpackChunkName: "pages/caras/leaderboard" */))
const _23258bb1 = () => interopDefault(import('../pages/comments.vue' /* webpackChunkName: "pages/comments" */))
const _66a94a7c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c5fcb136 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _38de2326 = () => interopDefault(import('../pages/discover.vue' /* webpackChunkName: "pages/discover" */))
const _31d6a8ea = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _85629608 = () => interopDefault(import('../pages/password/generate.vue' /* webpackChunkName: "pages/password/generate" */))
const _e6382640 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _6b07e16e = () => interopDefault(import('../pages/places.vue' /* webpackChunkName: "pages/places" */))
const _1044fe34 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _ed4b0ad2 = () => interopDefault(import('../pages/member.vue' /* webpackChunkName: "pages/member" */))
const _06387652 = () => interopDefault(import('../pages/legals.vue' /* webpackChunkName: "pages/legals" */))
const _0179e558 = () => interopDefault(import('../pages/mytrips/index.vue' /* webpackChunkName: "pages/mytrips/index" */))
const _48222750 = () => interopDefault(import('../pages/password/request.vue' /* webpackChunkName: "pages/password/request" */))
const _27aa12b6 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _c6507844 = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _763b2f1c = () => interopDefault(import('../pages/pictures.vue' /* webpackChunkName: "pages/pictures" */))
const _78f5afaa = () => interopDefault(import('../pages/premium.vue' /* webpackChunkName: "pages/premium" */))
const _d22dfb54 = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _1a6cce80 = () => interopDefault(import('../pages/pro.vue' /* webpackChunkName: "pages/pro" */))
const _345b2e13 = () => interopDefault(import('../pages/pro/_slug/activity.vue' /* webpackChunkName: "pages/pro/_slug/activity" */))
const _3c054476 = () => interopDefault(import('../pages/pro/_slug/poi.vue' /* webpackChunkName: "pages/pro/_slug/poi" */))
const _18c1da17 = () => interopDefault(import('../pages/substitute.vue' /* webpackChunkName: "pages/substitute" */))
const _6a896fea = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _2e938eb8 = () => interopDefault(import('../pages/teamlist.vue' /* webpackChunkName: "pages/teamlist" */))
const _2d8dcdcf = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _5cc6cb3a = () => interopDefault(import('../pages/caras/trombinoscope.vue' /* webpackChunkName: "pages/caras/trombinoscope" */))
const _1ad80846 = () => interopDefault(import('../pages/trips/home.vue' /* webpackChunkName: "pages/trips/home" */))
const _a85b2992 = () => interopDefault(import('../pages/activities/map.vue' /* webpackChunkName: "pages/activities/map" */))
const _9eba699c = () => interopDefault(import('../pages/activities/map/_slug.vue' /* webpackChunkName: "pages/activities/map/_slug" */))
const _3022a50a = () => interopDefault(import('../pages/activities/new.vue' /* webpackChunkName: "pages/activities/new" */))
const _9b3e78a4 = () => interopDefault(import('../pages/poi/new.vue' /* webpackChunkName: "pages/poi/new" */))
const _c856c680 = () => interopDefault(import('../pages/mediakit/contact.vue' /* webpackChunkName: "pages/mediakit/contact" */))
const _909be76a = () => interopDefault(import('../pages/trips/themes/index.vue' /* webpackChunkName: "pages/trips/themes/index" */))
const _409fd0c7 = () => interopDefault(import('../pages/trips/top.vue' /* webpackChunkName: "pages/trips/top" */))
const _5cbd8854 = () => interopDefault(import('../pages/trips/themes/_category.vue' /* webpackChunkName: "pages/trips/themes/_category" */))
const _40be701e = () => interopDefault(import('../pages/trips/themes/_country/index.vue' /* webpackChunkName: "pages/trips/themes/_country/index" */))
const _3bd02402 = () => interopDefault(import('../pages/trips/themes/_country/_category/index.vue' /* webpackChunkName: "pages/trips/themes/_country/_category/index" */))
const _0b87df60 = () => interopDefault(import('../pages/mytrips/_uuid/detail.vue' /* webpackChunkName: "pages/mytrips/_uuid/detail" */))
const _b61a3ece = () => interopDefault(import('../pages/partner/_slug/index.vue' /* webpackChunkName: "pages/partner/_slug/index" */))
const _118da08a = () => interopDefault(import('../pages/profile/_uuid/index.vue' /* webpackChunkName: "pages/profile/_uuid/index" */))
const _69461722 = () => interopDefault(import('../pages/trips/details/_slug.vue' /* webpackChunkName: "pages/trips/details/_slug" */))
const _1791bf41 = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _2242a980 = () => interopDefault(import('../pages/send/_slug.vue' /* webpackChunkName: "pages/send/_slug" */))
const _d496aa8e = () => interopDefault(import('../pages/directory/_country/index.vue' /* webpackChunkName: "pages/directory/_country/index" */))
const _c3e46a58 = () => interopDefault(import('../pages/activities/_uuid/edit.vue' /* webpackChunkName: "pages/activities/_uuid/edit" */))
const _0f714f68 = () => interopDefault(import('../pages/activities/_uuid/details.vue' /* webpackChunkName: "pages/activities/_uuid/details" */))
const _c6344d5a = () => interopDefault(import('../pages/activities/_uuid/pro.vue' /* webpackChunkName: "pages/activities/_uuid/pro" */))
const _c9da357e = () => interopDefault(import('../pages/poi/_uuid/edit.vue' /* webpackChunkName: "pages/poi/_uuid/edit" */))
const _a4431936 = () => interopDefault(import('../pages/dashboard/_uuid/details.vue' /* webpackChunkName: "pages/dashboard/_uuid/details" */))
const _a538783c = () => interopDefault(import('../pages/poi/_uuid/lock.vue' /* webpackChunkName: "pages/poi/_uuid/lock" */))
const _fad96cfc = () => interopDefault(import('../pages/profile/_uuid/edit.vue' /* webpackChunkName: "pages/profile/_uuid/edit" */))
const _5f6bb0a3 = () => interopDefault(import('../pages/profile/_uuid/delete.vue' /* webpackChunkName: "pages/profile/_uuid/delete" */))
const _146e509b = () => interopDefault(import('../pages/directory/_country/_department/index.vue' /* webpackChunkName: "pages/directory/_country/_department/index" */))
const _38ada965 = () => interopDefault(import('../pages/directory/_country/_department/_type/index.vue' /* webpackChunkName: "pages/directory/_country/_department/_type/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accueil",
    component: _54ed8f9c,
    name: "home___fr"
  }, {
    path: "/accueillant",
    component: _de659614,
    name: "caramaps_host___fr"
  }, {
    path: "/activites",
    component: _4d640226,
    name: "activities___fr"
  }, {
    path: "/ambassadeur",
    component: _4373a592,
    name: "caras-ambassador___fr"
  }, {
    path: "/annonceurs",
    component: _3b50da72,
    name: "mediakit___fr"
  }, {
    path: "/annuaire",
    component: _c2532efa,
    name: "directory___fr"
  }, {
    path: "/application",
    component: _60152ca3,
    name: "application___fr"
  }, {
    path: "/application-news",
    component: _d8459826,
    name: "application-news___fr"
  }, {
    path: "/awards",
    component: _1e30589a,
    name: "awards___fr"
  }, {
    path: "/carte",
    component: _5ec5d1af,
    name: "map___fr",
    children: [{
      path: "/carte/:slug",
      component: _ae4510ac,
      name: "map-slug___fr"
    }]
  }, {
    path: "/classement",
    component: _1e1061f2,
    name: "caras-leaderboard___fr"
  }, {
    path: "/commentaires",
    component: _23258bb1,
    name: "comments___fr"
  }, {
    path: "/connexion",
    component: _66a94a7c,
    name: "login___fr"
  }, {
    path: "/de",
    component: _c5fcb136,
    name: "index___de"
  }, {
    path: "/decouvrez",
    component: _38de2326,
    name: "discover___fr"
  }, {
    path: "/en",
    component: _c5fcb136,
    name: "index___en"
  }, {
    path: "/es",
    component: _c5fcb136,
    name: "index___es"
  }, {
    path: "/favoris",
    component: _31d6a8ea,
    name: "favorites___fr"
  }, {
    path: "/generer-nouveau-mot-de-passe",
    component: _85629608,
    name: "password-generate___fr"
  }, {
    path: "/inscription",
    component: _e6382640,
    name: "register___fr"
  }, {
    path: "/it",
    component: _c5fcb136,
    name: "index___it"
  }, {
    path: "/lieux",
    component: _6b07e16e,
    name: "places___fr"
  }, {
    path: "/maintenance",
    component: _1044fe34,
    name: "maintenance___fr"
  }, {
    path: "/membre",
    component: _ed4b0ad2,
    name: "member___fr"
  }, {
    path: "/mentions-legales",
    component: _06387652,
    name: "legals___fr"
  }, {
    path: "/mes-voyages",
    component: _0179e558,
    name: "mytrips___fr"
  }, {
    path: "/mot-de-passe-oublie",
    component: _48222750,
    name: "password-request___fr"
  }, {
    path: "/newsletter",
    component: _27aa12b6,
    name: "newsletter___fr"
  }, {
    path: "/nl",
    component: _c5fcb136,
    name: "index___nl"
  }, {
    path: "/partenaires",
    component: _c6507844,
    name: "partner___fr"
  }, {
    path: "/photos",
    component: _763b2f1c,
    name: "pictures___fr"
  }, {
    path: "/premium",
    component: _78f5afaa,
    name: "premium___fr"
  }, {
    path: "/presse",
    component: _d22dfb54,
    name: "press___fr"
  }, {
    path: "/pro",
    component: _1a6cce80,
    name: "pro___fr",
    children: [{
      path: "/pro/:slug/activity",
      component: _345b2e13,
      name: "pro-slug-activity___fr"
    }, {
      path: "/pro/:slug/poi",
      component: _3c054476,
      name: "pro-slug-poi___fr"
    }]
  }, {
    path: "/pt",
    component: _c5fcb136,
    name: "index___pt"
  }, {
    path: "/substitute",
    component: _18c1da17,
    name: "substitute___fr"
  }, {
    path: "/tableau-de-bord",
    component: _6a896fea,
    name: "dashboard___fr"
  }, {
    path: "/teamlist",
    component: _2e938eb8,
    name: "teamlist___fr"
  }, {
    path: "/test",
    component: _2d8dcdcf,
    name: "test___fr"
  }, {
    path: "/trombinoscope",
    component: _5cc6cb3a,
    name: "caras-trombinoscope___fr"
  }, {
    path: "/voyages",
    component: _1ad80846,
    name: "trips-home___fr"
  }, {
    path: "/activites/carte",
    component: _a85b2992,
    name: "activities-map___fr",
    children: [{
      path: "/activites/carte/:slug",
      component: _9eba699c,
      name: "activities-map-slug___fr"
    }]
  }, {
    path: "/activites/nouveau",
    component: _3022a50a,
    name: "activities-new___fr"
  }, {
    path: "/aire/ajouter",
    component: _9b3e78a4,
    name: "poi-new___fr"
  }, {
    path: "/annonceurs/kit-media",
    component: _c856c680,
    name: "mediakit-contact___fr"
  }, {
    path: "/de/aktivitaeten",
    component: _4d640226,
    name: "activities___de"
  }, {
    path: "/de/application",
    component: _60152ca3,
    name: "application___de"
  }, {
    path: "/de/application-news",
    component: _d8459826,
    name: "application-news___de"
  }, {
    path: "/de/armaturenbrett",
    component: _6a896fea,
    name: "dashboard___de"
  }, {
    path: "/de/awards",
    component: _1e30589a,
    name: "awards___de"
  }, {
    path: "/de/bilder",
    component: _763b2f1c,
    name: "pictures___de"
  }, {
    path: "/de/botschafter",
    component: _4373a592,
    name: "caras-ambassador___de"
  }, {
    path: "/de/entdecken",
    component: _38de2326,
    name: "discover___de"
  }, {
    path: "/de/favorites",
    component: _31d6a8ea,
    name: "favorites___de"
  }, {
    path: "/de/gastgeber",
    component: _de659614,
    name: "caramaps_host___de"
  }, {
    path: "/de/impressum",
    component: _06387652,
    name: "legals___de"
  }, {
    path: "/de/kommentare",
    component: _23258bb1,
    name: "comments___de"
  }, {
    path: "/de/landkarte",
    component: _5ec5d1af,
    name: "map___de",
    children: [{
      path: "/de/landkarte/:slug",
      component: _ae4510ac,
      name: "map-slug___de"
    }]
  }, {
    path: "/de/login",
    component: _66a94a7c,
    name: "login___de"
  }, {
    path: "/de/maintenance",
    component: _1044fe34,
    name: "maintenance___de"
  }, {
    path: "/de/meine-reise",
    component: _0179e558,
    name: "mytrips___de"
  }, {
    path: "/de/mitglied",
    component: _ed4b0ad2,
    name: "member___de"
  }, {
    path: "/de/neues-passwort-generieren",
    component: _85629608,
    name: "password-generate___de"
  }, {
    path: "/de/newsletter",
    component: _27aa12b6,
    name: "newsletter___de"
  }, {
    path: "/de/orte",
    component: _6b07e16e,
    name: "places___de"
  }, {
    path: "/de/partners",
    component: _c6507844,
    name: "partner___de"
  }, {
    path: "/de/passwort-vergessen",
    component: _48222750,
    name: "password-request___de"
  }, {
    path: "/de/pramie",
    component: _78f5afaa,
    name: "premium___de"
  }, {
    path: "/de/presse",
    component: _d22dfb54,
    name: "press___de"
  }, {
    path: "/de/pro",
    component: _1a6cce80,
    name: "pro___de",
    children: [{
      path: "/de/pro/:slug/activity",
      component: _345b2e13,
      name: "pro-slug-activity___de"
    }, {
      path: "/de/pro/:slug/poi",
      component: _3c054476,
      name: "pro-slug-poi___de"
    }]
  }, {
    path: "/de/ranking",
    component: _1e1061f2,
    name: "caras-leaderboard___de"
  }, {
    path: "/de/registrieren",
    component: _e6382640,
    name: "register___de"
  }, {
    path: "/de/reise",
    component: _1ad80846,
    name: "trips-home___de"
  }, {
    path: "/de/substitute",
    component: _18c1da17,
    name: "substitute___de"
  }, {
    path: "/de/teamlist",
    component: _2e938eb8,
    name: "teamlist___de"
  }, {
    path: "/de/test",
    component: _2d8dcdcf,
    name: "test___de"
  }, {
    path: "/de/trombinoskop",
    component: _5cc6cb3a,
    name: "caras-trombinoscope___de"
  }, {
    path: "/de/verzeichnis",
    component: _c2532efa,
    name: "directory___de"
  }, {
    path: "/de/werbetreibende",
    component: _3b50da72,
    name: "mediakit___de"
  }, {
    path: "/de/willkommen",
    component: _54ed8f9c,
    name: "home___de"
  }, {
    path: "/en/activities",
    component: _4d640226,
    name: "activities___en"
  }, {
    path: "/en/ambassador",
    component: _4373a592,
    name: "caras-ambassador___en"
  }, {
    path: "/en/application",
    component: _60152ca3,
    name: "application___en"
  }, {
    path: "/en/application-news",
    component: _d8459826,
    name: "application-news___en"
  }, {
    path: "/en/awards",
    component: _1e30589a,
    name: "awards___en"
  }, {
    path: "/en/comments",
    component: _23258bb1,
    name: "comments___en"
  }, {
    path: "/en/dashboard",
    component: _6a896fea,
    name: "dashboard___en"
  }, {
    path: "/en/directory",
    component: _c2532efa,
    name: "directory___en"
  }, {
    path: "/en/discover",
    component: _38de2326,
    name: "discover___en"
  }, {
    path: "/en/favorites",
    component: _31d6a8ea,
    name: "favorites___en"
  }, {
    path: "/en/forgot-password",
    component: _48222750,
    name: "password-request___en"
  }, {
    path: "/en/generate-new-password",
    component: _85629608,
    name: "password-generate___en"
  }, {
    path: "/en/home",
    component: _54ed8f9c,
    name: "home___en"
  }, {
    path: "/en/host",
    component: _de659614,
    name: "caramaps_host___en"
  }, {
    path: "/en/legals",
    component: _06387652,
    name: "legals___en"
  }, {
    path: "/en/login",
    component: _66a94a7c,
    name: "login___en"
  }, {
    path: "/en/maintenance",
    component: _1044fe34,
    name: "maintenance___en"
  }, {
    path: "/en/map",
    component: _5ec5d1af,
    name: "map___en",
    children: [{
      path: "/en/map/:slug",
      component: _ae4510ac,
      name: "map-slug___en"
    }]
  }, {
    path: "/en/mediakit",
    component: _3b50da72,
    name: "mediakit___en"
  }, {
    path: "/en/member",
    component: _ed4b0ad2,
    name: "member___en"
  }, {
    path: "/en/my-trips",
    component: _0179e558,
    name: "mytrips___en"
  }, {
    path: "/en/newsletter",
    component: _27aa12b6,
    name: "newsletter___en"
  }, {
    path: "/en/partners",
    component: _c6507844,
    name: "partner___en"
  }, {
    path: "/en/pictures",
    component: _763b2f1c,
    name: "pictures___en"
  }, {
    path: "/en/places",
    component: _6b07e16e,
    name: "places___en"
  }, {
    path: "/en/premium",
    component: _78f5afaa,
    name: "premium___en"
  }, {
    path: "/en/press",
    component: _d22dfb54,
    name: "press___en"
  }, {
    path: "/en/pro",
    component: _1a6cce80,
    name: "pro___en",
    children: [{
      path: "/en/pro/:slug/activity",
      component: _345b2e13,
      name: "pro-slug-activity___en"
    }, {
      path: "/en/pro/:slug/poi",
      component: _3c054476,
      name: "pro-slug-poi___en"
    }]
  }, {
    path: "/en/ranking",
    component: _1e1061f2,
    name: "caras-leaderboard___en"
  }, {
    path: "/en/register",
    component: _e6382640,
    name: "register___en"
  }, {
    path: "/en/substitute",
    component: _18c1da17,
    name: "substitute___en"
  }, {
    path: "/en/teamlist",
    component: _2e938eb8,
    name: "teamlist___en"
  }, {
    path: "/en/test",
    component: _2d8dcdcf,
    name: "test___en"
  }, {
    path: "/en/trips",
    component: _1ad80846,
    name: "trips-home___en"
  }, {
    path: "/en/trombinoscope",
    component: _5cc6cb3a,
    name: "caras-trombinoscope___en"
  }, {
    path: "/es/actividades",
    component: _4d640226,
    name: "activities___es"
  }, {
    path: "/es/anfitrion",
    component: _de659614,
    name: "caramaps_host___es"
  }, {
    path: "/es/anunciantes",
    component: _3b50da72,
    name: "mediakit___es"
  }, {
    path: "/es/application",
    component: _60152ca3,
    name: "application___es"
  }, {
    path: "/es/application-news",
    component: _d8459826,
    name: "application-news___es"
  }, {
    path: "/es/apuro",
    component: _d22dfb54,
    name: "press___es"
  }, {
    path: "/es/awards",
    component: _1e30589a,
    name: "awards___es"
  }, {
    path: "/es/clasificacion",
    component: _1e1061f2,
    name: "caras-leaderboard___es"
  }, {
    path: "/es/comentarios",
    component: _23258bb1,
    name: "comments___es"
  }, {
    path: "/es/contrasena-olvidada",
    component: _48222750,
    name: "password-request___es"
  }, {
    path: "/es/descubrir",
    component: _38de2326,
    name: "discover___es"
  }, {
    path: "/es/directorio",
    component: _c2532efa,
    name: "directory___es"
  }, {
    path: "/es/embajador",
    component: _4373a592,
    name: "caras-ambassador___es"
  }, {
    path: "/es/favorites",
    component: _31d6a8ea,
    name: "favorites___es"
  }, {
    path: "/es/generar-nueva-contrasena",
    component: _85629608,
    name: "password-generate___es"
  }, {
    path: "/es/home",
    component: _54ed8f9c,
    name: "home___es"
  }, {
    path: "/es/imagen",
    component: _763b2f1c,
    name: "pictures___es"
  }, {
    path: "/es/login",
    component: _66a94a7c,
    name: "login___es"
  }, {
    path: "/es/lugares",
    component: _6b07e16e,
    name: "places___es"
  }, {
    path: "/es/maintenance",
    component: _1044fe34,
    name: "maintenance___es"
  }, {
    path: "/es/mapa",
    component: _5ec5d1af,
    name: "map___es",
    children: [{
      path: "/es/mapa/:slug",
      component: _ae4510ac,
      name: "map-slug___es"
    }]
  }, {
    path: "/es/miembro",
    component: _ed4b0ad2,
    name: "member___es"
  }, {
    path: "/es/mis-viajes",
    component: _0179e558,
    name: "mytrips___es"
  }, {
    path: "/es/newsletter",
    component: _27aa12b6,
    name: "newsletter___es"
  }, {
    path: "/es/notas-legales",
    component: _06387652,
    name: "legals___es"
  }, {
    path: "/es/premium",
    component: _78f5afaa,
    name: "premium___es"
  }, {
    path: "/es/pro",
    component: _1a6cce80,
    name: "pro___es",
    children: [{
      path: "/es/pro/:slug/activity",
      component: _345b2e13,
      name: "pro-slug-activity___es"
    }, {
      path: "/es/pro/:slug/poi",
      component: _3c054476,
      name: "pro-slug-poi___es"
    }]
  }, {
    path: "/es/registro",
    component: _e6382640,
    name: "register___es"
  }, {
    path: "/es/socios",
    component: _c6507844,
    name: "partner___es"
  }, {
    path: "/es/substitute",
    component: _18c1da17,
    name: "substitute___es"
  }, {
    path: "/es/tablero-de-control",
    component: _6a896fea,
    name: "dashboard___es"
  }, {
    path: "/es/teamlist",
    component: _2e938eb8,
    name: "teamlist___es"
  }, {
    path: "/es/test",
    component: _2d8dcdcf,
    name: "test___es"
  }, {
    path: "/es/trombinoscopio",
    component: _5cc6cb3a,
    name: "caras-trombinoscope___es"
  }, {
    path: "/es/viajes",
    component: _1ad80846,
    name: "trips-home___es"
  }, {
    path: "/it/ambasciatore",
    component: _4373a592,
    name: "caras-ambassador___it"
  }, {
    path: "/it/application",
    component: _60152ca3,
    name: "application___it"
  }, {
    path: "/it/application-news",
    component: _d8459826,
    name: "application-news___it"
  }, {
    path: "/it/attivita",
    component: _4d640226,
    name: "activities___it"
  }, {
    path: "/it/awards",
    component: _1e30589a,
    name: "awards___it"
  }, {
    path: "/it/commenti",
    component: _23258bb1,
    name: "comments___it"
  }, {
    path: "/it/cruscotto",
    component: _6a896fea,
    name: "dashboard___it"
  }, {
    path: "/it/elenco",
    component: _c2532efa,
    name: "directory___it"
  }, {
    path: "/it/favorites",
    component: _31d6a8ea,
    name: "favorites___it"
  }, {
    path: "/it/fretta",
    component: _d22dfb54,
    name: "press___it"
  }, {
    path: "/it/generare-nuova-password",
    component: _85629608,
    name: "password-generate___it"
  }, {
    path: "/it/home",
    component: _54ed8f9c,
    name: "home___it"
  }, {
    path: "/it/i-miei-viaggi",
    component: _0179e558,
    name: "mytrips___it"
  }, {
    path: "/it/immagini",
    component: _763b2f1c,
    name: "pictures___it"
  }, {
    path: "/it/inserzionisti",
    component: _3b50da72,
    name: "mediakit___it"
  }, {
    path: "/it/login",
    component: _66a94a7c,
    name: "login___it"
  }, {
    path: "/it/luoghi",
    component: _6b07e16e,
    name: "places___it"
  }, {
    path: "/it/maintenance",
    component: _1044fe34,
    name: "maintenance___it"
  }, {
    path: "/it/mappa",
    component: _5ec5d1af,
    name: "map___it",
    children: [{
      path: "/it/mappa/:slug",
      component: _ae4510ac,
      name: "map-slug___it"
    }]
  }, {
    path: "/it/membro",
    component: _ed4b0ad2,
    name: "member___it"
  }, {
    path: "/it/menzioni-legali",
    component: _06387652,
    name: "legals___it"
  }, {
    path: "/it/newsletter",
    component: _27aa12b6,
    name: "newsletter___it"
  }, {
    path: "/it/ospite",
    component: _de659614,
    name: "caramaps_host___it"
  }, {
    path: "/it/partner",
    component: _c6507844,
    name: "partner___it"
  }, {
    path: "/it/password-dimenticata",
    component: _48222750,
    name: "password-request___it"
  }, {
    path: "/it/premio",
    component: _78f5afaa,
    name: "premium___it"
  }, {
    path: "/it/pro",
    component: _1a6cce80,
    name: "pro___it",
    children: [{
      path: "/it/pro/:slug/activity",
      component: _345b2e13,
      name: "pro-slug-activity___it"
    }, {
      path: "/it/pro/:slug/poi",
      component: _3c054476,
      name: "pro-slug-poi___it"
    }]
  }, {
    path: "/it/ranking",
    component: _1e1061f2,
    name: "caras-leaderboard___it"
  }, {
    path: "/it/registrare",
    component: _e6382640,
    name: "register___it"
  }, {
    path: "/it/scoprire",
    component: _38de2326,
    name: "discover___it"
  }, {
    path: "/it/substitute",
    component: _18c1da17,
    name: "substitute___it"
  }, {
    path: "/it/teamlist",
    component: _2e938eb8,
    name: "teamlist___it"
  }, {
    path: "/it/test",
    component: _2d8dcdcf,
    name: "test___it"
  }, {
    path: "/it/trombinoscopio",
    component: _5cc6cb3a,
    name: "caras-trombinoscope___it"
  }, {
    path: "/it/viaggi",
    component: _1ad80846,
    name: "trips-home___it"
  }, {
    path: "/nl/aanmelden",
    component: _e6382640,
    name: "register___nl"
  }, {
    path: "/nl/activiteiten",
    component: _4d640226,
    name: "activities___nl"
  }, {
    path: "/nl/advertenties",
    component: _3b50da72,
    name: "mediakit___nl"
  }, {
    path: "/nl/afbeeldingen",
    component: _763b2f1c,
    name: "pictures___nl"
  }, {
    path: "/nl/ambassadeur",
    component: _4373a592,
    name: "caras-ambassador___nl"
  }, {
    path: "/nl/application",
    component: _60152ca3,
    name: "application___nl"
  }, {
    path: "/nl/application-news",
    component: _d8459826,
    name: "application-news___nl"
  }, {
    path: "/nl/awards",
    component: _1e30589a,
    name: "awards___nl"
  }, {
    path: "/nl/dashboard",
    component: _6a896fea,
    name: "dashboard___nl"
  }, {
    path: "/nl/directory",
    component: _c2532efa,
    name: "directory___nl"
  }, {
    path: "/nl/favorites",
    component: _31d6a8ea,
    name: "favorites___nl"
  }, {
    path: "/nl/gast",
    component: _de659614,
    name: "caramaps_host___nl"
  }, {
    path: "/nl/haast-je",
    component: _d22dfb54,
    name: "press___nl"
  }, {
    path: "/nl/home",
    component: _54ed8f9c,
    name: "home___nl"
  }, {
    path: "/nl/kaart",
    component: _5ec5d1af,
    name: "map___nl",
    children: [{
      path: "/nl/kaart/:slug",
      component: _ae4510ac,
      name: "map-slug___nl"
    }]
  }, {
    path: "/nl/lid",
    component: _ed4b0ad2,
    name: "member___nl"
  }, {
    path: "/nl/login",
    component: _66a94a7c,
    name: "login___nl"
  }, {
    path: "/nl/maintenance",
    component: _1044fe34,
    name: "maintenance___nl"
  }, {
    path: "/nl/mijn-reizen",
    component: _0179e558,
    name: "mytrips___nl"
  }, {
    path: "/nl/newsletter",
    component: _27aa12b6,
    name: "newsletter___nl"
  }, {
    path: "/nl/nieuw-wachtwoord-genereren",
    component: _85629608,
    name: "password-generate___nl"
  }, {
    path: "/nl/ontvang",
    component: _38de2326,
    name: "discover___nl"
  }, {
    path: "/nl/opmerkingen",
    component: _23258bb1,
    name: "comments___nl"
  }, {
    path: "/nl/partners",
    component: _c6507844,
    name: "partner___nl"
  }, {
    path: "/nl/plaatsen",
    component: _6b07e16e,
    name: "places___nl"
  }, {
    path: "/nl/premium",
    component: _78f5afaa,
    name: "premium___nl"
  }, {
    path: "/nl/pro",
    component: _1a6cce80,
    name: "pro___nl",
    children: [{
      path: "/nl/pro/:slug/activity",
      component: _345b2e13,
      name: "pro-slug-activity___nl"
    }, {
      path: "/nl/pro/:slug/poi",
      component: _3c054476,
      name: "pro-slug-poi___nl"
    }]
  }, {
    path: "/nl/ranking",
    component: _1e1061f2,
    name: "caras-leaderboard___nl"
  }, {
    path: "/nl/reizen",
    component: _1ad80846,
    name: "trips-home___nl"
  }, {
    path: "/nl/substitute",
    component: _18c1da17,
    name: "substitute___nl"
  }, {
    path: "/nl/teamlist",
    component: _2e938eb8,
    name: "teamlist___nl"
  }, {
    path: "/nl/test",
    component: _2d8dcdcf,
    name: "test___nl"
  }, {
    path: "/nl/trombinoscoop",
    component: _5cc6cb3a,
    name: "caras-trombinoscope___nl"
  }, {
    path: "/nl/wachtwoord-vergeten",
    component: _48222750,
    name: "password-request___nl"
  }, {
    path: "/nl/wettelijke",
    component: _06387652,
    name: "legals___nl"
  }, {
    path: "/pt/anfitriao",
    component: _de659614,
    name: "caramaps_host___pt"
  }, {
    path: "/pt/anunciantes",
    component: _3b50da72,
    name: "mediakit___pt"
  }, {
    path: "/pt/application",
    component: _60152ca3,
    name: "application___pt"
  }, {
    path: "/pt/application-news",
    component: _d8459826,
    name: "application-news___pt"
  }, {
    path: "/pt/as-minhas-viagens",
    component: _0179e558,
    name: "mytrips___pt"
  }, {
    path: "/pt/atividades",
    component: _4d640226,
    name: "activities___pt"
  }, {
    path: "/pt/awards",
    component: _1e30589a,
    name: "awards___pt"
  }, {
    path: "/pt/classificacao",
    component: _1e1061f2,
    name: "caras-leaderboard___pt"
  }, {
    path: "/pt/comentarios",
    component: _23258bb1,
    name: "comments___pt"
  }, {
    path: "/pt/descobrir",
    component: _38de2326,
    name: "discover___pt"
  }, {
    path: "/pt/diretorio",
    component: _c2532efa,
    name: "directory___pt"
  }, {
    path: "/pt/embaixador",
    component: _4373a592,
    name: "caras-ambassador___pt"
  }, {
    path: "/pt/esquecida-senha",
    component: _48222750,
    name: "password-request___pt"
  }, {
    path: "/pt/favorites",
    component: _31d6a8ea,
    name: "favorites___pt"
  }, {
    path: "/pt/fotos",
    component: _763b2f1c,
    name: "pictures___pt"
  }, {
    path: "/pt/gerar-nova-senha",
    component: _85629608,
    name: "password-generate___pt"
  }, {
    path: "/pt/home",
    component: _54ed8f9c,
    name: "home___pt"
  }, {
    path: "/pt/legais",
    component: _06387652,
    name: "legals___pt"
  }, {
    path: "/pt/login",
    component: _66a94a7c,
    name: "login___pt"
  }, {
    path: "/pt/lugares",
    component: _6b07e16e,
    name: "places___pt"
  }, {
    path: "/pt/maintenance",
    component: _1044fe34,
    name: "maintenance___pt"
  }, {
    path: "/pt/mapa",
    component: _5ec5d1af,
    name: "map___pt",
    children: [{
      path: "/pt/mapa/:slug",
      component: _ae4510ac,
      name: "map-slug___pt"
    }]
  }, {
    path: "/pt/membro",
    component: _ed4b0ad2,
    name: "member___pt"
  }, {
    path: "/pt/newsletter",
    component: _27aa12b6,
    name: "newsletter___pt"
  }, {
    path: "/pt/painel-de-controle",
    component: _6a896fea,
    name: "dashboard___pt"
  }, {
    path: "/pt/parceiros",
    component: _c6507844,
    name: "partner___pt"
  }, {
    path: "/pt/premio",
    component: _78f5afaa,
    name: "premium___pt"
  }, {
    path: "/pt/pressa",
    component: _d22dfb54,
    name: "press___pt"
  }, {
    path: "/pt/pro",
    component: _1a6cce80,
    name: "pro___pt",
    children: [{
      path: "/pt/pro/:slug/activity",
      component: _345b2e13,
      name: "pro-slug-activity___pt"
    }, {
      path: "/pt/pro/:slug/poi",
      component: _3c054476,
      name: "pro-slug-poi___pt"
    }]
  }, {
    path: "/pt/registrar",
    component: _e6382640,
    name: "register___pt"
  }, {
    path: "/pt/substitute",
    component: _18c1da17,
    name: "substitute___pt"
  }, {
    path: "/pt/teamlist",
    component: _2e938eb8,
    name: "teamlist___pt"
  }, {
    path: "/pt/test",
    component: _2d8dcdcf,
    name: "test___pt"
  }, {
    path: "/pt/trombinoscopio",
    component: _5cc6cb3a,
    name: "caras-trombinoscope___pt"
  }, {
    path: "/pt/viagens",
    component: _1ad80846,
    name: "trips-home___pt"
  }, {
    path: "/voyages/categories",
    component: _909be76a,
    name: "trips-themes___fr"
  }, {
    path: "/voyages/recommandations",
    component: _409fd0c7,
    name: "trips-top___fr"
  }, {
    path: "/de/activities/karte",
    component: _a85b2992,
    name: "activities-map___de",
    children: [{
      path: "/de/activities/karte/:slug",
      component: _9eba699c,
      name: "activities-map-slug___de"
    }]
  }, {
    path: "/de/aktivitaeten/neu",
    component: _3022a50a,
    name: "activities-new___de"
  }, {
    path: "/de/area/willkommen",
    component: _9b3e78a4,
    name: "poi-new___de"
  }, {
    path: "/de/reise/kategorien",
    component: _909be76a,
    name: "trips-themes___de"
  }, {
    path: "/de/reise/oben",
    component: _409fd0c7,
    name: "trips-top___de"
  }, {
    path: "/de/werbetreibende/medien-kit",
    component: _c856c680,
    name: "mediakit-contact___de"
  }, {
    path: "/en/activities/map",
    component: _a85b2992,
    name: "activities-map___en",
    children: [{
      path: "/en/activities/map/:slug",
      component: _9eba699c,
      name: "activities-map-slug___en"
    }]
  }, {
    path: "/en/activities/new",
    component: _3022a50a,
    name: "activities-new___en"
  }, {
    path: "/en/area/add",
    component: _9b3e78a4,
    name: "poi-new___en"
  }, {
    path: "/en/mediakit/media-kit",
    component: _c856c680,
    name: "mediakit-contact___en"
  }, {
    path: "/en/trips/categories",
    component: _909be76a,
    name: "trips-themes___en"
  }, {
    path: "/en/trips/top",
    component: _409fd0c7,
    name: "trips-top___en"
  }, {
    path: "/es/actividades/mapa",
    component: _a85b2992,
    name: "activities-map___es",
    children: [{
      path: "/es/actividades/mapa/:slug",
      component: _9eba699c,
      name: "activities-map-slug___es"
    }]
  }, {
    path: "/es/actividades/nuevo",
    component: _3022a50a,
    name: "activities-new___es"
  }, {
    path: "/es/anunciantes/kit-de-medios",
    component: _c856c680,
    name: "mediakit-contact___es"
  }, {
    path: "/es/area/anadir",
    component: _9b3e78a4,
    name: "poi-new___es"
  }, {
    path: "/es/viajes/arriba",
    component: _409fd0c7,
    name: "trips-top___es"
  }, {
    path: "/es/viajes/categorias",
    component: _909be76a,
    name: "trips-themes___es"
  }, {
    path: "/it/area/aggiungere",
    component: _9b3e78a4,
    name: "poi-new___it"
  }, {
    path: "/it/attivita/mappa",
    component: _a85b2992,
    name: "activities-map___it",
    children: [{
      path: "/it/attivita/mappa/:slug",
      component: _9eba699c,
      name: "activities-map-slug___it"
    }]
  }, {
    path: "/it/attivita/nuovo",
    component: _3022a50a,
    name: "activities-new___it"
  }, {
    path: "/it/inserzionisti/kit-media",
    component: _c856c680,
    name: "mediakit-contact___it"
  }, {
    path: "/it/viaggi/categorie",
    component: _909be76a,
    name: "trips-themes___it"
  }, {
    path: "/it/viaggi/cima",
    component: _409fd0c7,
    name: "trips-top___it"
  }, {
    path: "/nl/activiteiten/map",
    component: _a85b2992,
    name: "activities-map___nl",
    children: [{
      path: "/nl/activiteiten/map/:slug",
      component: _9eba699c,
      name: "activities-map-slug___nl"
    }]
  }, {
    path: "/nl/activiteiten/nieuw",
    component: _3022a50a,
    name: "activities-new___nl"
  }, {
    path: "/nl/advertenties/media-kit",
    component: _c856c680,
    name: "mediakit-contact___nl"
  }, {
    path: "/nl/gebied/toevoegen",
    component: _9b3e78a4,
    name: "poi-new___nl"
  }, {
    path: "/nl/reizen/boven",
    component: _409fd0c7,
    name: "trips-top___nl"
  }, {
    path: "/nl/reizen/categorieen",
    component: _909be76a,
    name: "trips-themes___nl"
  }, {
    path: "/pt/anunciantes/kit-de-midia",
    component: _c856c680,
    name: "mediakit-contact___pt"
  }, {
    path: "/pt/area/adcionar",
    component: _9b3e78a4,
    name: "poi-new___pt"
  }, {
    path: "/pt/atividades/mapa",
    component: _a85b2992,
    name: "activities-map___pt",
    children: [{
      path: "/pt/atividades/mapa/:slug",
      component: _9eba699c,
      name: "activities-map-slug___pt"
    }]
  }, {
    path: "/pt/atividades/novo",
    component: _3022a50a,
    name: "activities-new___pt"
  }, {
    path: "/pt/viagens/categorias",
    component: _909be76a,
    name: "trips-themes___pt"
  }, {
    path: "/pt/viagens/topo",
    component: _409fd0c7,
    name: "trips-top___pt"
  }, {
    path: "/de/reise/kategorien/:category",
    component: _5cbd8854,
    name: "trips-themes-category___de"
  }, {
    path: "/de/reise/lander/:country",
    component: _40be701e,
    name: "trips-themes-country___de"
  }, {
    path: "/en/trips/categories/:category",
    component: _5cbd8854,
    name: "trips-themes-category___en"
  }, {
    path: "/en/trips/countries/:country",
    component: _40be701e,
    name: "trips-themes-country___en"
  }, {
    path: "/es/viajes/categorias/:category",
    component: _5cbd8854,
    name: "trips-themes-category___es"
  }, {
    path: "/es/viajes/paises/:country",
    component: _40be701e,
    name: "trips-themes-country___es"
  }, {
    path: "/it/viaggi/categorie/:category",
    component: _5cbd8854,
    name: "trips-themes-category___it"
  }, {
    path: "/it/viaggi/paesi/:country",
    component: _40be701e,
    name: "trips-themes-country___it"
  }, {
    path: "/nl/reizen/categorieen/:category",
    component: _5cbd8854,
    name: "trips-themes-category___nl"
  }, {
    path: "/nl/reizen/landen/:country",
    component: _40be701e,
    name: "trips-themes-country___nl"
  }, {
    path: "/pt/viagens/categorias/:category",
    component: _5cbd8854,
    name: "trips-themes-category___pt"
  }, {
    path: "/pt/viagens/paises/:country",
    component: _40be701e,
    name: "trips-themes-country___pt"
  }, {
    path: "/de/reise/kategorien/:category/lander/:country",
    component: _3bd02402,
    name: "trips-themes-country-category___de"
  }, {
    path: "/en/trips/categories/:category/countries/:country",
    component: _3bd02402,
    name: "trips-themes-country-category___en"
  }, {
    path: "/es/viajes/categorias/:category/paises/:country",
    component: _3bd02402,
    name: "trips-themes-country-category___es"
  }, {
    path: "/it/viaggi/categorie/:category/paesi/:country",
    component: _3bd02402,
    name: "trips-themes-country-category___it"
  }, {
    path: "/nl/reizen/categorieen/:category/landen/:country",
    component: _3bd02402,
    name: "trips-themes-country-category___nl"
  }, {
    path: "/pt/viagens/categorias/:category/paises/:country",
    component: _3bd02402,
    name: "trips-themes-country-category___pt"
  }, {
    path: "/de/meine-reise/:uuid",
    component: _0b87df60,
    name: "mytrips-uuid-detail___de"
  }, {
    path: "/de/partners/:slug",
    component: _b61a3ece,
    name: "partner-slug___de"
  }, {
    path: "/de/profil/:uuid",
    component: _118da08a,
    name: "profile-uuid___de"
  }, {
    path: "/de/reise/:slug",
    component: _69461722,
    name: "trips-details-slug___de"
  }, {
    path: "/de/reset/:token",
    component: _1791bf41,
    name: "password-reset-token___de"
  }, {
    path: "/de/send/:slug",
    component: _2242a980,
    name: "send-slug___de"
  }, {
    path: "/de/verzeichnis/:country",
    component: _d496aa8e,
    name: "directory-country___de"
  }, {
    path: "/en/directory/:country",
    component: _d496aa8e,
    name: "directory-country___en"
  }, {
    path: "/en/my-trips/:uuid",
    component: _0b87df60,
    name: "mytrips-uuid-detail___en"
  }, {
    path: "/en/partners/:slug",
    component: _b61a3ece,
    name: "partner-slug___en"
  }, {
    path: "/en/profile/:uuid",
    component: _118da08a,
    name: "profile-uuid___en"
  }, {
    path: "/en/reset/:token",
    component: _1791bf41,
    name: "password-reset-token___en"
  }, {
    path: "/en/send/:slug",
    component: _2242a980,
    name: "send-slug___en"
  }, {
    path: "/en/trips/:slug",
    component: _69461722,
    name: "trips-details-slug___en"
  }, {
    path: "/es/directorio/:country",
    component: _d496aa8e,
    name: "directory-country___es"
  }, {
    path: "/es/mis-viajes/:uuid",
    component: _0b87df60,
    name: "mytrips-uuid-detail___es"
  }, {
    path: "/es/perfil/:uuid",
    component: _118da08a,
    name: "profile-uuid___es"
  }, {
    path: "/es/reset/:token",
    component: _1791bf41,
    name: "password-reset-token___es"
  }, {
    path: "/es/send/:slug",
    component: _2242a980,
    name: "send-slug___es"
  }, {
    path: "/es/socios/:slug",
    component: _b61a3ece,
    name: "partner-slug___es"
  }, {
    path: "/es/viajes/:slug",
    component: _69461722,
    name: "trips-details-slug___es"
  }, {
    path: "/it/elenco/:country",
    component: _d496aa8e,
    name: "directory-country___it"
  }, {
    path: "/it/i-miei-viaggi/:uuid",
    component: _0b87df60,
    name: "mytrips-uuid-detail___it"
  }, {
    path: "/it/partner/:slug",
    component: _b61a3ece,
    name: "partner-slug___it"
  }, {
    path: "/it/profilo/:uuid",
    component: _118da08a,
    name: "profile-uuid___it"
  }, {
    path: "/it/reset/:token",
    component: _1791bf41,
    name: "password-reset-token___it"
  }, {
    path: "/it/send/:slug",
    component: _2242a980,
    name: "send-slug___it"
  }, {
    path: "/it/viaggi/:slug",
    component: _69461722,
    name: "trips-details-slug___it"
  }, {
    path: "/nl/directory/:country",
    component: _d496aa8e,
    name: "directory-country___nl"
  }, {
    path: "/nl/mijn-reizen/:uuid",
    component: _0b87df60,
    name: "mytrips-uuid-detail___nl"
  }, {
    path: "/nl/partners/:slug",
    component: _b61a3ece,
    name: "partner-slug___nl"
  }, {
    path: "/nl/profiel/:uuid",
    component: _118da08a,
    name: "profile-uuid___nl"
  }, {
    path: "/nl/reizen/:slug",
    component: _69461722,
    name: "trips-details-slug___nl"
  }, {
    path: "/nl/reset/:token",
    component: _1791bf41,
    name: "password-reset-token___nl"
  }, {
    path: "/nl/send/:slug",
    component: _2242a980,
    name: "send-slug___nl"
  }, {
    path: "/pt/as-minhas-viagens/:uuid",
    component: _0b87df60,
    name: "mytrips-uuid-detail___pt"
  }, {
    path: "/pt/diretorio/:country",
    component: _d496aa8e,
    name: "directory-country___pt"
  }, {
    path: "/pt/parceiros/:slug",
    component: _b61a3ece,
    name: "partner-slug___pt"
  }, {
    path: "/pt/perfil/:uuid",
    component: _118da08a,
    name: "profile-uuid___pt"
  }, {
    path: "/pt/reset/:token",
    component: _1791bf41,
    name: "password-reset-token___pt"
  }, {
    path: "/pt/send/:slug",
    component: _2242a980,
    name: "send-slug___pt"
  }, {
    path: "/pt/viagens/:slug",
    component: _69461722,
    name: "trips-details-slug___pt"
  }, {
    path: "/voyages/categories/:category",
    component: _5cbd8854,
    name: "trips-themes-category___fr"
  }, {
    path: "/voyages/pays/:country",
    component: _40be701e,
    name: "trips-themes-country___fr"
  }, {
    path: "/de/aktivitaeten/:uuid/bearbeiten",
    component: _c3e46a58,
    name: "activities-uuid-edit___de"
  }, {
    path: "/de/aktivitaeten/:uuid/details",
    component: _0f714f68,
    name: "activities-uuid-details___de"
  }, {
    path: "/de/aktivitaeten/:uuid/pro",
    component: _c6344d5a,
    name: "activities-uuid-pro___de"
  }, {
    path: "/de/area/:uuid/bearbeiten",
    component: _c9da357e,
    name: "poi-uuid-edit___de"
  }, {
    path: "/de/armaturenbrett/:uuid/details",
    component: _a4431936,
    name: "dashboard-uuid-details___de"
  }, {
    path: "/de/bereich/:uuid/sperren",
    component: _a538783c,
    name: "poi-uuid-lock___de"
  }, {
    path: "/de/profil/:uuid/bearbeiten",
    component: _fad96cfc,
    name: "profile-uuid-edit___de"
  }, {
    path: "/de/profil/:uuid/delete",
    component: _5f6bb0a3,
    name: "profile-uuid-delete___de"
  }, {
    path: "/en/activities/:uuid/details",
    component: _0f714f68,
    name: "activities-uuid-details___en"
  }, {
    path: "/en/activities/:uuid/edit",
    component: _c3e46a58,
    name: "activities-uuid-edit___en"
  }, {
    path: "/en/activities/:uuid/pro",
    component: _c6344d5a,
    name: "activities-uuid-pro___en"
  }, {
    path: "/en/area/:uuid/edit",
    component: _c9da357e,
    name: "poi-uuid-edit___en"
  }, {
    path: "/en/area/:uuid/lock",
    component: _a538783c,
    name: "poi-uuid-lock___en"
  }, {
    path: "/en/dashboard/:uuid/details",
    component: _a4431936,
    name: "dashboard-uuid-details___en"
  }, {
    path: "/en/profile/:uuid/delete",
    component: _5f6bb0a3,
    name: "profile-uuid-delete___en"
  }, {
    path: "/en/profile/:uuid/edit",
    component: _fad96cfc,
    name: "profile-uuid-edit___en"
  }, {
    path: "/es/actividades/:uuid/detalles",
    component: _0f714f68,
    name: "activities-uuid-details___es"
  }, {
    path: "/es/actividades/:uuid/editar",
    component: _c3e46a58,
    name: "activities-uuid-edit___es"
  }, {
    path: "/es/actividades/:uuid/pro",
    component: _c6344d5a,
    name: "activities-uuid-pro___es"
  }, {
    path: "/es/area/:uuid/bloquear",
    component: _a538783c,
    name: "poi-uuid-lock___es"
  }, {
    path: "/es/area/:uuid/editar",
    component: _c9da357e,
    name: "poi-uuid-edit___es"
  }, {
    path: "/es/perfil/:uuid/delete",
    component: _5f6bb0a3,
    name: "profile-uuid-delete___es"
  }, {
    path: "/es/perfil/:uuid/editar",
    component: _fad96cfc,
    name: "profile-uuid-edit___es"
  }, {
    path: "/es/tablero-de-control/:uuid/detalles",
    component: _a4431936,
    name: "dashboard-uuid-details___es"
  }, {
    path: "/it/area/:uuid/modificare",
    component: _c9da357e,
    name: "poi-uuid-edit___it"
  }, {
    path: "/it/attivita/:uuid/dettagli",
    component: _0f714f68,
    name: "activities-uuid-details___it"
  }, {
    path: "/it/attivita/:uuid/modifica",
    component: _c3e46a58,
    name: "activities-uuid-edit___it"
  }, {
    path: "/it/attivita/:uuid/pro",
    component: _c6344d5a,
    name: "activities-uuid-pro___it"
  }, {
    path: "/it/cruscotto/:uuid/dettagli",
    component: _a4431936,
    name: "dashboard-uuid-details___it"
  }, {
    path: "/it/profilo/:uuid/delete",
    component: _5f6bb0a3,
    name: "profile-uuid-delete___it"
  }, {
    path: "/it/profilo/:uuid/modificare",
    component: _fad96cfc,
    name: "profile-uuid-edit___it"
  }, {
    path: "/it/zona/:uuid/bloccare",
    component: _a538783c,
    name: "poi-uuid-lock___it"
  }, {
    path: "/nl/activiteiten/:uuid/bewerken",
    component: _c3e46a58,
    name: "activities-uuid-edit___nl"
  }, {
    path: "/nl/activiteiten/:uuid/details",
    component: _0f714f68,
    name: "activities-uuid-details___nl"
  }, {
    path: "/nl/activiteiten/:uuid/pro",
    component: _c6344d5a,
    name: "activities-uuid-pro___nl"
  }, {
    path: "/nl/dashboard/:uuid/details",
    component: _a4431936,
    name: "dashboard-uuid-details___nl"
  }, {
    path: "/nl/gebied/:uuid/blokkeren",
    component: _a538783c,
    name: "poi-uuid-lock___nl"
  }, {
    path: "/nl/gebied/:uuid/edit",
    component: _c9da357e,
    name: "poi-uuid-edit___nl"
  }, {
    path: "/nl/profiel/:uuid/bewerken",
    component: _fad96cfc,
    name: "profile-uuid-edit___nl"
  }, {
    path: "/nl/profiel/:uuid/delete",
    component: _5f6bb0a3,
    name: "profile-uuid-delete___nl"
  }, {
    path: "/pt/area/:uuid/bloquear",
    component: _a538783c,
    name: "poi-uuid-lock___pt"
  }, {
    path: "/pt/area/:uuid/editar",
    component: _c9da357e,
    name: "poi-uuid-edit___pt"
  }, {
    path: "/pt/atividades/:uuid/detalhes",
    component: _0f714f68,
    name: "activities-uuid-details___pt"
  }, {
    path: "/pt/atividades/:uuid/editar",
    component: _c3e46a58,
    name: "activities-uuid-edit___pt"
  }, {
    path: "/pt/atividades/:uuid/pro",
    component: _c6344d5a,
    name: "activities-uuid-pro___pt"
  }, {
    path: "/pt/painel-de-controle/:uuid/detalhes",
    component: _a4431936,
    name: "dashboard-uuid-details___pt"
  }, {
    path: "/pt/perfil/:uuid/delete",
    component: _5f6bb0a3,
    name: "profile-uuid-delete___pt"
  }, {
    path: "/pt/perfil/:uuid/editar",
    component: _fad96cfc,
    name: "profile-uuid-edit___pt"
  }, {
    path: "/voyages/categories/:category/pays/:country",
    component: _3bd02402,
    name: "trips-themes-country-category___fr"
  }, {
    path: "/de/verzeichnis/:country/:department",
    component: _146e509b,
    name: "directory-country-department___de"
  }, {
    path: "/en/directory/:country/:department",
    component: _146e509b,
    name: "directory-country-department___en"
  }, {
    path: "/es/directorio/:country/:department",
    component: _146e509b,
    name: "directory-country-department___es"
  }, {
    path: "/it/elenco/:country/:department",
    component: _146e509b,
    name: "directory-country-department___it"
  }, {
    path: "/nl/directory/:country/:department",
    component: _146e509b,
    name: "directory-country-department___nl"
  }, {
    path: "/pt/diretorio/:country/:department",
    component: _146e509b,
    name: "directory-country-department___pt"
  }, {
    path: "/de/verzeichnis/:country/:department/:type",
    component: _38ada965,
    name: "directory-country-department-type___de"
  }, {
    path: "/en/directory/:country/:department/:type",
    component: _38ada965,
    name: "directory-country-department-type___en"
  }, {
    path: "/es/directorio/:country/:department/:type",
    component: _38ada965,
    name: "directory-country-department-type___es"
  }, {
    path: "/it/elenco/:country/:department/:type",
    component: _38ada965,
    name: "directory-country-department-type___it"
  }, {
    path: "/nl/directory/:country/:department/:type",
    component: _38ada965,
    name: "directory-country-department-type___nl"
  }, {
    path: "/pt/diretorio/:country/:department/:type",
    component: _38ada965,
    name: "directory-country-department-type___pt"
  }, {
    path: "/annuaire/:country",
    component: _d496aa8e,
    name: "directory-country___fr"
  }, {
    path: "/envoyer/:slug",
    component: _2242a980,
    name: "send-slug___fr"
  }, {
    path: "/mes-voyages/:uuid",
    component: _0b87df60,
    name: "mytrips-uuid-detail___fr"
  }, {
    path: "/partenaires/:slug",
    component: _b61a3ece,
    name: "partner-slug___fr"
  }, {
    path: "/profil/:uuid",
    component: _118da08a,
    name: "profile-uuid___fr"
  }, {
    path: "/reset/:token",
    component: _1791bf41,
    name: "password-reset-token___fr"
  }, {
    path: "/voyages/:slug",
    component: _69461722,
    name: "trips-details-slug___fr"
  }, {
    path: "/activites/:uuid/details",
    component: _0f714f68,
    name: "activities-uuid-details___fr"
  }, {
    path: "/activites/:uuid/modifier",
    component: _c3e46a58,
    name: "activities-uuid-edit___fr"
  }, {
    path: "/activites/:uuid/pro",
    component: _c6344d5a,
    name: "activities-uuid-pro___fr"
  }, {
    path: "/aire/:uuid/bloquer",
    component: _a538783c,
    name: "poi-uuid-lock___fr"
  }, {
    path: "/aire/:uuid/editer",
    component: _c9da357e,
    name: "poi-uuid-edit___fr"
  }, {
    path: "/profil/:uuid/delete",
    component: _5f6bb0a3,
    name: "profile-uuid-delete___fr"
  }, {
    path: "/profil/:uuid/editer",
    component: _fad96cfc,
    name: "profile-uuid-edit___fr"
  }, {
    path: "/tableau-de-bord/:uuid/details",
    component: _a4431936,
    name: "dashboard-uuid-details___fr"
  }, {
    path: "/annuaire/:country/:department",
    component: _146e509b,
    name: "directory-country-department___fr"
  }, {
    path: "/annuaire/:country/:department/:type",
    component: _38ada965,
    name: "directory-country-department-type___fr"
  }, {
    path: "/",
    component: _c5fcb136,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
