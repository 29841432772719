import { render, staticRenderFns } from "./SubHeader.vue?vue&type=template&id=62de1cb2&"
import script from "./SubHeader.vue?vue&type=script&lang=ts&"
export * from "./SubHeader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/caramaps/site/dvp/www2/releases/20241010132410/components/base/BaseIcon.vue').default,SubHeaderLink: require('/home/caramaps/site/dvp/www2/releases/20241010132410/components/SubHeaderLink.vue').default})
