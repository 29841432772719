import { render, staticRenderFns } from "./LangSwitcher.vue?vue&type=template&id=2e04d2ae&scoped=true&"
import script from "./LangSwitcher.vue?vue&type=script&lang=ts&"
export * from "./LangSwitcher.vue?vue&type=script&lang=ts&"
import style0 from "./LangSwitcher.vue?vue&type=style&index=0&id=2e04d2ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e04d2ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/caramaps/site/dvp/www2/releases/20241010132410/components/base/BaseIcon.vue').default})
